<template>
  <card title="成果展示" :withbg="true">
    <el-row class="achieve-list" :gutter="20">
      <el-col :span="6" v-for="item of showList" :key="item.code">
        <div class="achieve-col">
          <p>
            <span class="num">{{ item.num }}</span>
            <span class="unit">{{ item.unit }}</span>
          </p>
          <p class="dec">
            <img :src="item.icon" />
            {{ item.name }}
          </p>
        </div>
      </el-col>
    </el-row>
  </card>
</template>

<script>
import Card from "@/components/Card/index.vue";
import { mapGetters } from "vuex";
import {
  // getSubjectInfo,
  // getCumulativeLoanIssuance,
  // getCumulativeLandTrust,
  // getSumArea,
  // getSumCjje,
  SelectOrderData,
  SelectXMXXData,
  SelectCompanyData,
} from "@/api/displayResults.js";
const showList = [
  {
    code: 1,
    num: 97113.93,
    unit: "亩",
    icon: require("@/assets/icon-show-1.png"),
    name: "资源资产流转交易",
  },
  {
    code: 2,
    num: 2333.66,
    unit: "亩",
    icon: require("@/assets/icon-show-2.png"),
    name: "累计土地托管",
  },
  {
    code: 3,
    num: 0,
    unit: "万元",
    icon: require("@/assets/icon-show-3.png"),
    name: "累计发放贷款",
  },
  {
    code: 4,
    num: 0,
    unit: "家",
    icon: require("@/assets/icon-show-4.png"),
    name: "红名单主体",
  },
  {
    code: 5,
    num: 55563.15,
    unit: "万元",
    icon: require("@/assets/icon-show-3.png"),
    name: "资源资产交易流转金额",
  },
  {
    code: 6,
    num: 2372,
    unit: "家",
    icon: require("@/assets/icon-show-5.png"),
    name: "新型经营主体",
  },
  {
    code: 7,
    num: 0,
    unit: "家",
    icon: require("@/assets/icon-show-6.png"),
    name: "累计评定信用主体",
  },
  {
    code: 8,
    num: 0,
    unit: "家",
    icon: require("@/assets/icon-show-7.png"),
    name: "黑名单主体",
  },
];

export default {
  components: {
    Card,
  },
  computed:{...mapGetters(["topSelectAreacode"])},
  data() {
    return {
      showList,
    };
  },
  watch: {
    topSelectAreacode() {
      this.init();
    },
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      // this.getLJFFDK(); //3
      // this.getLJTDTG(); //2
      // // this.getZYZCLZJY();
      // // this.getZYZCJYLZJE();
      // this.getSubject(); // 4 6 7 8
      // this.getDataOne();//1
      // this.getDataFive();//5
      this.getOne()
      this.getTwo()
      this.getThree()
    },
// 首页查看托管单数据统计
    async  getOne(){
        let res= await  SelectOrderData({
          areaCode:this.topSelectAreacode
        })
        //  console.log(res,'查看有没有值')
         if(res.code==0){
            this.showList[1].num =res.data.totalArea? res.data.totalArea :0
         }
      },
// 首页查看资源资产数据统计
      async getTwo(){
        let res= await  SelectXMXXData({
          areaCode:this.topSelectAreacode
        })
        //  console.log(res,'查看有没有值')
         if(res.code==0){
            this.showList[0].num =res.data.totalArea? res.data.totalArea :0
            this.showList[4].num =res.data.totalAmount? (res.data.totalAmount/10000).toFixed(2)  :0
         }
      },
      async getThree(){
        let res= await  SelectCompanyData({
          areaCode:this.topSelectAreacode
        })

         if(res.code==0){
            this.showList[5].num =res.data? res.data :0
         }
      }

    // // 资源资产流转交易
    // async getDataOne() {
    //   let res = await getSumArea({
    //     areaCode:this.topSelectAreacode
    //   });
    //   if(res.status) {
    //     this.showList[0].num =  res.data ? res.data.toFixed(2) : 0;
    //   }
    // },

    // // 资源资产交易流转金额
    // async getDataFive() {
    //   let res = await getSumCjje({
    //     areaCode:this.topSelectAreacode
    //   });
    //   if(res.status) {
    //     console.log(res);
    //     this.showList[4].num =  res.data ? (res.data / 10000).toFixed(2) : 0;
    //   }
    // },

    // //获取主体信息
    // async getSubject() {
    //   let res = await getSubjectInfo({
    //     areaCode:this.topSelectAreacode
    //   });
    //   if (!res || res.code != 0) {
    //     return;
    //   }
    //   let data = res.data;
    //   this.showList.forEach((item) => {
    //     switch (item.code) {
    //       case 8:
    //         item.num = data.blackListTotal ? data.blackListTotal : 0;
    //         break;
    //       case 4:
    //         item.num = data.redListTotal ? data.redListTotal : 0;
    //         break;
    //       case 6:
    //         item.num = data.total ? data.total : 0;
    //         break;
    //       case 7:
    //         item.num = data.totalEvaluated ? data.totalEvaluated : 0;
    //         break;
    //     }
    //   });
    // },
    // //累计发放贷款
    // async getLJFFDK() {
    //   let res = await getCumulativeLoanIssuance({
    //     areaCode:this.topSelectAreacode
    //   });
    //   if (!res || res.code != 0) {
    //     return;
    //   }
    //   let data = res.data;
    //   this.showList.forEach((item) => {
    //     switch (item.code) {
    //       case 3:
    //         item.num = data ? (data/10000).toFixed(2) : 0;
    //         break;
    //     }
    //   });
    // },

    // //累计土地托管
    // async getLJTDTG() {
    //   let res = await getCumulativeLandTrust({
    //     areaCode:this.topSelectAreacode
    //   });
    //   if (!res || res.code != 0) {
    //     return;
    //   }
    //   let data = res.data;
    //   this.showList.forEach((item) => {
    //     switch (item.code) {
    //       case 2:
    //         item.num = data ? data.totalArea.toFixed(2) : 0;
    //         break;
    //     }
    //   });
    // },
    //资源资产流转交易
    // async getZYZCLZJY() {
    //   let res = await getResourceAssetTransferTransaction();
    //   if (!res || res.status != true) {
    //     return;
    //   }
    //   let data = res.total;
    //   this.showList.forEach((item) => {
    //     switch (item.code) {
    //       case 1:
    //         item.num = data ? data.toFixed(2) : 0;
    //         break;
    //     }
    //   });
    // },
    //资源资产交易流转金额
    // async getZYZCJYLZJE() {
    //   let res = await getResourceAssetTransferAmount();
    //   if (!res || res.status != true) {
    //     return;
    //   }
    //   let data = res.total;
    //   this.showList.forEach((item) => {
    //     switch (item.code) {
    //       case 5:
    //         item.num = data ? (data / 10000).toFixed(2) : 0;
    //         break;
    //     }
    //   });
    // },
  },
};
</script>

<style lang="less" scope>
.achieve-list {
  height: 400px;
  .achieve-col {
    background: #fff;
    border: 1px solid #dcdcdc;
    padding: 20px 0px 20px 40px;
    margin: 15px 0;
    p {
      padding: 0;
      margin: 0;
    }

    .num {
      display: inline-block;
      max-width: 280px;
      font-size: 48px;
      color: #333;
      font-family: "DIN-Alternate-Bold";
    }

    .dec {
      font-size: 18px;
      color: #666;
      display: flex;
      align-items: center;

      img {
        margin-right: 6px;
      }
    }
  }
}
</style>
