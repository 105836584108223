<template>
  <div class="homepage">
    <div class="allShrink">
      <div class="header-banner">
        <div class="login-area" v-if="!showLogin">
          <login-area></login-area>
        </div>
        <div class="swiper">
          <el-carousel
            class="carousel"
            :interval="4000"
            :loop="true"
            arrow="always"
          >
            <el-carousel-item>
              <p class="slogan">以农为本 为农服务 助农增收</p>
              <img src="../../assets/imgs/banner1.png" alt="" />
            </el-carousel-item>
            <el-carousel-item>
              <img src="../../assets/imgs/banner1.png" alt="" />
            </el-carousel-item>
          </el-carousel>
        </div>
      </div>
      <!-- 集采公告公示 -->
      <Announcement></Announcement>

      <!-- 快速入口 -->
      <quick-link></quick-link>

      <!-- 竞价大厅 -->
      <resource-hall></resource-hall>

      <!-- 供求资讯 -->
      <supply-demand></supply-demand>

      <!-- 成果展示 -->
      <achieve-display></achieve-display>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { getStore } from "@/js/utils/store";
import LoginArea from "@/components/LoginArea";
import Announcement from "./components/Announcement.vue";
// import vueSeamlessScroll from "vue-seamless-scroll";
import QuickLink from "./components/QuickLink.vue";
import ResourceHall from "./components/ResourceHall.vue";
import SupplyDemand from "./components/SupplyDemand.vue";
import AchieveDisplay from "./components/AchieveDisplay.vue";

export default {
  components: {
    LoginArea,
    QuickLink,
    ResourceHall,
    SupplyDemand,
    AchieveDisplay,
    Announcement,
    // vueSeamlessScroll,
  },
  data() {
    return {
      showLogin: getStore({ name: "identityObj" })
        ? getStore({ name: "identityObj" }).identityTypeCode
        : "",
    };
  },
  computed: {
    ...mapGetters(["topSelectAreacode"]),
  },

  destroyed() {},
  methods: {
    async getDynamic() {
      let params = {
        current: 1,
        size: 24,
        areaCode: this.topSelectAreacode,
      };
      let res = await selectProcurementNotice(params);
      if (!res || res.code != 0) {
        return;
      }
      this.newsList = res.data.records;
    },

    liClick(e) {
      let info = "";
      this.newsList.forEach((ele) => {
        if (ele.id == e.target.dataset.title) {
          info = ele;
        }
      });
    },
    ClickBtn(item) {
      console.log(item);
      this.$router.push({ name: "Preview", query: { data: item.secondId } });
    },
  },
};
</script>


<style lang="less" scoped>
.query /deep/ .el-select > .el-input {
  width: 235px;
}

.homepage {
  text-align: left;
  background: #fff;
  display: grid;
  .slogan {
    top: 70px;
  }
  .allShrink {
    width: 90%;
    margin: auto;
  }
}
</style>

<style lang="less">
.swiper {
  .el-carousel__container {
    height: 100%;
  }
}
</style>
